.header {
  @apply bg-blue-950;
  @apply h-20;
  @apply flex;
  @apply items-center;
  @apply sticky;
  @apply top-0;
  @apply justify-between;
  @apply z-10;
  @apply px-5;

  &__logo {
    @apply py-4;
    @apply h-full;
    @apply flex-1;
    @apply min-w-0;
  }

  &__links {
    @apply space-x-12;
    @apply flex;
    @apply items-center;
  }

  &__join {
    @apply flex-1;
    @apply min-w-0;
    @apply flex;
    @apply justify-end;
  }
}

.logo {
  @apply w-auto;
  @apply h-full;
  @apply inline-flex;
}

@screen mobile_lg {
  .header {
    @apply px-10;
  }
}

@scren tablet_lg {
  .header {
    @apply px-14;
  }
}
