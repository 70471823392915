.diagrams {
  &__menu_slider {
    @apply overflow-hidden;
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply relative;
    @apply justify-center;
    @apply items-center;

    &_header {
      @apply w-auto;
      @apply select-none;
    }

    &_name {
      @apply whitespace-nowrap;
      @apply uppercase;
      @apply text-sm;
      @apply font-normal;
      @apply mb-20;

      &_active {
        @apply text-blue-600;
        @apply border-b-2;
        @apply border-blue-600;
        @apply pb-2;
      }
    }

    & > div {
      @apply w-full;
    }

    &_content {
      @apply bg-blue-900;
      @apply mx-5;
      @apply px-5;
      @apply py-10;
    }

    &_title {
      @apply mb-8;
      @apply text-2xl;
      @apply text-center;
    }

    &_img {
      @apply mb-8;
    }
  }
}

@screen mobile_lg {
  .diagrams__menu_slider {
    &_content {
      @apply my-0;
      @apply mx-10;
      @apply px-12;
      @apply py-14;
    }

    &_title {
      @apply mb-10;
    }

    &_img {
      @apply mb-10;
    }
  }
}
