@layer components {
  .link {
    @apply text-lg;
    @apply text-gray-200;
    @apply transition-colors;
    &:hover {
      @apply text-blue-600;
    }
    &:active {
      @apply text-blue-600;
    }
  }
  .content-link {
    @apply text-blue-600;
    @apply transition-colors;
    &:hover {
      @apply text-blue-300;
    }
    &:active {
      @apply text-blue-300;
    }
  }
}
