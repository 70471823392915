@layer components {
  .h2 {
    @apply text-gray-200;
    @apply flex;
    @apply flex-col;
    @apply text-4xl;
    @apply font-bold;
    @apply space-y-8;
    @apply items-center;
    @apply pb-4;
    @apply pt-36;
  }
}
