.slide {
  @apply flex;
  @apply flex-col;
  @apply space-y-4;
  @apply items-center;
  @apply px-5;
  @apply text-center;

  &__text {
    @apply text-gray-400;
    @apply max-w-2xl;
    @apply text-center;
  }

  &__btn {
    @apply pt-4;
    @apply w-full;

    & > button {
      @apply w-full;
    }
  }
}

.icons {
  @apply flex;
  @apply space-x-8;
}

@screen tablet {
  .slide {
    @apply px-10;

    &__btn {
      @apply pt-4;
      @apply w-auto;

      & > button {
        @apply w-auto;
      }
    }
  }
}
