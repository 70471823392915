.slide {
  //   @apply relative;
  &__h2 {
    @media (orientation: landscape) {
      @apply pt-16;
      @screen tablet_lg {
        @apply pt-36;
      }
    }
  }
}

.fab {
  @apply hidden;
  @apply sticky;
  @apply bottom-28;
  height: 0;
  @apply justify-end;
  @apply pr-14;
}

.diagrams {
  @apply flex;
  @apply space-x-10;
  @apply max-w-7xl;
  @apply mx-auto;
  @apply mb-32;

  &__menu {
    @apply py-4;
    @apply w-1/4;
    @apply space-y-3.5;
    &_item {
      @apply px-4;
      @apply py-2;
      @apply relative;
      @apply items-center;
      @apply flex;
      @apply cursor-pointer;
      @apply uppercase;
      @apply font-medium;
      @apply transition-colors;
      @apply text-sm;

      &:before {
        @apply border-l-2;
        border-color: transparent;
        content: '';
        @apply absolute;
        @apply left-2;
        height: 35%;
      }
      &:hover {
        @apply text-blue-300;
      }
      &--active {
        @apply text-blue-300;
        &:before {
          @apply border-blue-300;
        }
      }
    }
  }

  &__content {
    @apply bg-blue-900;
    @apply p-10;
    @apply flex-1;
    @apply min-w-0;

    @media (orientation: landscape) {
      @apply pt-4;
      @screen tablet_lg {
        @apply pt-10;
      }
    }

    &_item {
      display: none;
      &--active {
        display: block;
      }
    }
  }
}

.content {
  @apply flex;
  @apply flex-col;
  @apply space-y-8;
  &__header {
    @apply font-bold;
    @apply text-2xl;
    @apply text-center;
  }

  &__list {
    @apply text-gray-400;
    & > & {
      @apply ml-6;
    }
    &_item {
      @apply relative;
      @apply px-4;
      @apply py-2;
      &::before {
        content: '\25CF';
        position: absolute;
        left: 0;
        display: block;
        @apply text-sm;
        top: 10px;
      }
    }
  }

  &__svg {
    @apply mx-auto;
  }

  &__img {
    @apply mx-auto;
  }

  &__text {
    @apply text-gray-400;
  }

  &__type_ {
    &NFT {
      img {
        max-width: min(580px, 100%);
      }
    }
    &MM {
      img {
        max-width: min(580px, 100%);
        transform: translateX(3%);
      }
    }
    &SM {
      img {
        max-width: min(700px, 100%);
      }
    }
    &BE {
      img {
        max-width: min(690px, 100%);
      }
    }
    &EE {
      img {
        max-width: min(700px, 100%);
      }
    }
    &FIX {
      img {
        max-width: min(750px, 100%);
        transform: translateX(3%);
      }
    }
    &OTC {
      img {
        max-width: min(690px, 100%);
      }
    }
    &ST {
      img {
        max-width: min(700px, 100%);
        transform: translateX(3%);
      }
    }
  }
}

@screen tablet {
  .fab {
    @apply flex;
  }
}
