.slide {
  @apply flex;
  @apply flex-col;
  position: relative;
  @media (orientation: landscape) {
    @apply pt-6;
  }

  &__info {
    @apply flex;
    @apply items-center;
    @apply flex-col;
    @apply text-gray-400;
    @apply space-y-6;
    @apply text-base;
    @apply h-2/5;
    @apply justify-end;
    @media (orientation: landscape) {
      height: auto;
      @apply space-y-3;
      @screen tablet {
        @apply space-y-6;
      }
    }
    @screen tablet_lg {
      @apply h-2/5;
      @media (orientation: landscape) {
        @apply h-2/5;
      }
    }

    & > div {
      @apply w-full;
      @apply text-center;
      @apply px-5;
    }
  }

  &__anim {
    @apply h-3/5;
    @apply w-full;
  }
}

.logo {
  @apply h-9;
}

.btn {
  @apply pt-0;
  @screen tablet {
    @apply pt-4;
  }
}

.text {
  max-width: 610px;
}

@screen tablet {
  .slide {
    &__info {
      @apply text-xl;

      & > div {
        @apply w-auto;
      }
    }
  }
  .logo {
    @apply h-12;
  }
}
