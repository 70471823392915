@layer components {
  .btn {
    @apply min-h-[2rem];
    @apply px-6;
    @apply py-1;
    @apply font-medium;
    @apply rounded;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply transition-colors;
    @apply space-x-4;

    &-outline {
      @apply border;
      @apply border-gray-600;
      @apply text-gray-200;
      &:hover {
        @apply bg-gray-200;
        @apply text-blue-900;
      }
      &:active {
        @apply bg-gray-400;
        @apply text-blue-900;
      }
    }

    &-primary {
      border: 1px solid transparent;
      @apply bg-blue-600;
      @apply text-gray-200;
      @apply shadow-btn;
      @apply shadow-blue-600;
      &:hover {
        @apply bg-blue-300;
      }
    }

    &-secondary {
      @apply border;
      @apply border-green-400;
      @apply text-green-400;
      &:hover {
        @apply bg-green-400;
        @apply text-blue-950;
      }
      &:active {
        @apply bg-green-500;
        @apply text-blue-950;
      }
    }
  }
}
