@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';
@import 'content';
@import 'buttons';
@import 'heading';
@import 'links';

body {
  @apply bg-blue-950;
  @apply text-gray-200;
}

.content-container {
  min-height: 80vh;
}
