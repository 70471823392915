.slide {
  @apply flex;
  @apply flex-col;
  @apply items-center;

  &__header {
    @apply pt-32;
  }

  &__diagram {
    @apply w-full;
    height: 330px;
    @apply relative;
    @apply overflow-hidden;
    @apply py-10;

    & > svg {
      width: 440px;
      height: 305px;
      @apply absolute;
      left: calc(50% - 220px);
    }
  }
  &__lines {
    @apply w-full;

    @apply h-36;
    @apply relative;
    @apply mt-4;
    pointer-events: none;

    @screen tablet {
      @apply mt-20;
    }

    @screen md {
      @apply pb-10;
      @apply mt-32;
      @apply h-64;
    }

    @screen xl {
      @apply h-72;
    }

    @screen 2k {
      height: 450px;
    }
  }
}

.lines {
  @apply w-full;
  @apply h-80;
  @apply absolute;

  @screen 2k {
    height: 500px;
  }

  &__g {
    transform: translate(-35%, 0);
    path {
      stroke-width: 5px;
    }
    @screen tablet {
      path {
        stroke-width: 3px;
      }
    }
    @screen desktop {
      path {
        stroke-width: 2px;
      }
    }
  }
}

.svg {
  &__circle {
    stroke-width: 2;
  }
  &__g {
    circle {
      r: 25;
      @apply fill-blue-600;
      @apply transition-colors;
      @apply cursor-pointer;
    }
    &_text {
      @apply fill-gray-200;
      alignment-baseline: middle;
      @apply uppercase;
      @apply text-sm;
      @apply transition-colors;
      @apply cursor-pointer;
      @apply hidden;
    }
    &_text_center {
      @apply fill-gray-200;
      @apply text-base;
      @apply font-bold;
      @apply cursor-pointer;
      text-anchor: middle;
    }

    &_circle {
      @apply fill-blue-600;
      @apply transition-colors;
      @apply cursor-pointer;
      &--active {
        @apply fill-sky-300;
      }
    }
    &:hover {
      .svg__g_circle {
        @apply fill-blue-300;
        &--active {
          @apply fill-sky-300;
        }
      }
      &_text {
        @apply fill-gray-400;
      }
    }
  }
}

@screen mobile_lg {
  .slide {
    &__diagram {
      height: 520px;

      & svg {
        width: 600px;
        height: 420px;
        left: calc(50% - 600px / 2);
      }
    }
  }

  .svg {
    &__g {
      circle {
        r: 15;
      }

      &_text_center {
        font-size: 10px;
        transform: translateY(-2px);
      }
    }
  }
}

@screen tablet {
  .slide {
    @apply space-y-4;

    &__header {
      @apply pt-36;
    }

    &__lines {
      @apply mt-0;
    }
  }
  .svg {
    &__g {
      circle {
        r: 9.5;
      }
      &_text {
        @apply block;
      }

      &_text_center {
        @apply hidden;
      }
    }
  }
}

@screen tablet_lg {
  .slide {
    &__diagram {
      @apply w-auto;
      @apply h-auto;
      @apply static;

      & > svg {
        width: 821px;
        height: 570px;
        @apply static;
      }
    }
  }
}
