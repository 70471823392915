.container {
  @apply flex;
  @apply flex-col;
  @apply text-gray-600;
  @apply font-medium;
  @apply absolute;
  @apply bottom-24;
  left: 50%;
  transform: translate(-50%, 0);
  @apply items-center;
  @apply cursor-pointer;
  @apply space-y-4;
  @screen tablet {
    @apply bottom-14;
  }
  
  @media (orientation: landscape) {
    display: none;
    @screen tablet_lg {
      @apply flex;
    }
  }
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrow {
  @apply stroke-gray-600;
  animation: arrow 2s infinite;
  &__1 {
    animation-delay: -0.25s;
  }
  &__2 {
    animation-delay: 0s;
  }
  &__text {
    animation-delay: -0.5s;
  }
}
