$stroke-width-base: 5;
$stroke-width-desktop: 2.5;

.svg {
  @apply h-full;
  @apply w-full;
  @apply relative;
}

.container {
  @apply h-full;
  @apply w-full;
  @apply absolute;
  transform: translateY(-30%);
  pointer-events: none;
}

.g1 {
  transform: translate(-21%, -30%);
}

.g2 {
  transform: translate(-21%, 10%);
}

.path1 {
  @apply stroke-blue-800;
  stroke-width: $stroke-width-base;
  @screen desktop {
    stroke-width: $stroke-width-desktop;
  }

  &--anim {
    @apply stroke-sky-300;
  }
}

.circle1 {
  @apply stroke-sky-300;
  @apply fill-blue-950;
  stroke-width: $stroke-width-base;
  @screen desktop {
    stroke-width: $stroke-width-desktop;
  }
}

.path2 {
  @apply stroke-blue-800;
  stroke-width: $stroke-width-base;
  @screen desktop {
    stroke-width: $stroke-width-desktop;
  }
  &--anim {
    @apply stroke-blue-600;
  }
}

.circle2 {
  @apply stroke-blue-600;
  @apply fill-blue-950;
  stroke-width: $stroke-width-base;
  @screen desktop {
    stroke-width: $stroke-width-desktop;
  }
}
