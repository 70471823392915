.button {
  @apply flex;
  @apply items-center;
  @apply justify-end;
  @apply text-white;
  @apply w-10;
  @apply h-10;

  svg {
    width: 30px;
  }
}

.mobile_menu {
  &_links {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply text-lg;
    @apply font-normal;
    transform: translateY(-300px);
    transition: transform 0.1s 1s;

    & a {
      @apply mb-7;
    }

    & > a:nth-child(1) {
      @apply opacity-0;
      transition: opacity 0.3s 0.4s;
    }

    & > a:nth-child(2) {
      @apply opacity-0;
      transition: opacity 0.3s 0.3s;
    }

    & > a:nth-child(3) {
      @apply opacity-0;
      transition: opacity 0.3s 0.2s;
    }
  }

  &_join {
    @apply mt-3;
    @apply opacity-0;
    transition: opacity 0.3s 0.1s;
    & > a {
      @apply text-base;
      @apply font-medium;
      @apply py-2;
      @apply border-gray-600;
    }
  }
}

.icon {
  @apply w-6;
  @apply h-6;
}

.menu {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  z-index: -1;
  @apply h-0;
  @apply overflow-hidden;
  @apply transition-[height];
  @apply duration-1000;
  @apply bg-blue-950;
  @apply px-5;
  @apply flex;
  @apply flex-col;
  @apply pt-24;

  &__content {
    @apply pt-20;
    @apply space-y-6;
    @apply flex;
    @apply flex-col;

    &--show {
      > * {
        @apply opacity-100;
      }
    }
  }

  &--show {
    @apply h-screen;

    &_links {
      transition: transform 0.1s;
      transform: translateY(0px);

      & > a:nth-child(1) {
        @apply opacity-100;
        transition: opacity 0.3s 0.2s;
      }

      & > a:nth-child(2) {
        @apply opacity-100;
        transition: opacity 0.3s 0.3s;
      }

      & > a:nth-child(3) {
        @apply opacity-100;
        transition: opacity 0.3s 0.4s;
      }
    }

    &_join {
      @apply opacity-100;
        transition: opacity 0.3s 0.5s;
    }
  }
}

.no-scroll {
  @apply overflow-hidden;
  @apply w-full;
}

@screen mobile_lg {
  .menu {
    @apply px-10;
  }
}
