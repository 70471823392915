.footer {
  @apply flex;
  @apply pt-10;
  @apply mt-5;
  @apply items-center;
  @apply flex-col;
  @apply px-7;
  @apply mb-10;

  &__cr {
    @apply text-gray-400;
    @apply min-w-0;
    @apply text-right;
    @apply text-sm;
    @apply order-1;
    @apply mt-4;
  }

  &__contacts {
    @apply flex;
    @apply justify-around;
    @apply flex-col;
    @apply mb-2;
  }

  &__logo {
    @apply min-w-0;
    @apply order-1;
  }
}

.item {
  @apply flex;
  @apply space-x-4;
  @apply items-center;
  @apply mb-8;
  &__info {
    &_label {
      @apply text-gray-400;
      @apply text-sm;
      @apply mb-2;
    }
  }
}

@screen tablet_lg {
  .footer {
    @apply flex-row;
    @apply flex-wrap;
    @apply px-14;
    @apply justify-center;

    .item {
      @apply mb-0;
    }

    &__contacts {
      @apply flex-row;
      @apply space-x-16;
      @apply mb-10;
      @apply w-full;
    }

    &__cr {
      @apply ml-10;
      @apply mt-0;
    }
  }
}

@screen desktop {
  .footer {
    @apply h-36;
    @apply mb-0;
    @apply flex-nowrap;

    &__contacts {
      @apply w-auto;
      @apply mb-0;
    }

    &__cr {
      @apply flex-1;
      @apply order-none;
    }

    &__logo {
      @apply flex-1;
      @apply order-none;
    }
  }
}
